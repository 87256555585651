import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import isEmpty from "is-empty";
import bona from "../../utils/psga_new.png";
var x;
class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      userId: "",
      token: "",
      password: "",
      password2: "",
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.password === "") {
      errors.password = "This field is required";
    } else if (this.state.password.length < 8) {
      errors.password = "Password length should be 8 characters";
    } else {
      delete errors.password;
    }
    if (this.state.password2 === "") {
      errors.password2 = "This field is required";
    } else if (this.state.password !== this.state.password2) {
      errors.password2 = "Password not match";
    } else {
      delete errors.password2;
    }
    if (isEmpty(errors)) {
      axios({
        method: "POST",
        url:
          "/api/password-reset/" +
          this.props.match.params.userId +
          "/" +
          this.props.match.params.token,
        data: { password: this.state.password },
      }).then((res) => {
        toast(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        this.props.history.push("/login");
      });
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-6 mx-auto mt-5 card shadow-lg">
            <div className="card-body p-1">
              <h2
                className="text-center text-primary mt-3 "
                style={{ marginBottom: "50px" }}
              >
                <img src={bona} height={50} />
              </h2>

              <div
                className="tab-pane fade show active"
                id="pills-group"
                role="tabpanel"
                aria-labelledby="pills-group-tab"
              >
                <form noValidate onSubmit={this.onSubmit} className="white">
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="password">Password</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        autoComplete={""}
                        onChange={this.onChange}
                        value={this.state.password}
                        error={errors.password}
                        id="password"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.password,
                        })}
                      />
                      <span className="text-danger">{errors.password}</span>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="password2">Confirm Password</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        autoComplete={""}
                        onChange={this.onChange}
                        value={this.state.password2}
                        id="password2"
                        type="password"
                        className={classnames("form-control", {
                          invalid: errors.password2,
                        })}
                      />
                      <span className="text-danger">{errors.password2}</span>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-large btn-primary mt-2 px-5"
                  >
                    Update Password
                  </button>
                </form>
              </div>
            </div>
            <br></br>
            <br></br>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { loginUser })(ResetPassword);
