import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import classnames from "classnames";
import isEmpty from "is-empty";
import bona from "../../utils/psga_new.png";
import $ from "jquery";
import PulseLoader from "react-spinners/PulseLoader";
import { confirmAlert } from "react-confirm-alert";
import usState from "../../utils/state.json";
class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      practice_owner_first_name: "",
      practice_owner_last_name: "",
      practice_owner_phone: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      fax: "",
      npi: "",
      personal_email: "",
      phone1: "",
      phone2: "",
      addressline_2: "",
      billing_street: "",
      billing_city: "",
      billing_state: "",
      billing_zip: "",
      billing_addressline_2: "",
      shipping_street: "",
      shipping_city: "",
      shipping_state: "",
      shipping_zip: "",
      shipping_addressline_2: "",
      sameas: "",
      state_license: "",
      newval: {},
      state_license: null,
      dea_license: null,
      section: "register",
      errors: {},
      loading: false,
      dea_license_no: undefined,
      dea_license_exp: undefined,
      state_license_no: undefined,
      state_license_exp: undefined,
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = (e) => {
    let current = e.target.id;
    this.setState({ [current]: e.target.value }, () => this.checkNpi(current));
  };

  checkNpi = (currentKey) => {
    if (currentKey === "npi" && this.state.npi.length === 10) {
      this.setState({ loading: true });
      axios
        .post("/api/call-npi", {
          url: `https://npiregistry.cms.hhs.gov/api/?number=${this.state.npi}&version=2.1`,
          npi: this.state.npi,
        })
        .then((res) => {
          if (res.data.message === "exists") {
            confirmAlert({
              title: "Error!",
              message: `There is an account associated with this NPI. Please try to use different NPI`,
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {
                    this.setState({ npi: "", loading: false });
                  },
                },
              ],
              overlayClassName: "confirm-plan",
            });
          } else {
            let data = JSON.parse(res.data.data);
            if (data.Errors && data.Errors.length) {
              let { errors } = this.state;
              errors.npi = data.Errors[0].description;
              this.setState({
                errors: errors,
                loading: false,
                name: "",
              });
            } else if (data.results.length === 0) {
              let { errors } = this.state;
              errors.npi = "NPI not found";
              this.setState({
                errors: errors,
                loading: false,
                name: "",
              });
            } else {
              let data1 = data.results[0];
              let { errors } = this.state;
              delete errors.npi;
              let obj = {
                name: data1.basic.organization_name,
                loading: false,
                errors,
              };
              this.setState(obj);
            }
          }
        });
    }
  };

  setFromDate = (date) => {
    this.setState({ assigned_date: date });
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let { errors } = this.state;
    if (this.state.name === "") {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }
    if (
      this.state.state_license === null ||
      this.state.state_license === undefined
    ) {
      errors.state_license = "This field is required";
    } else {
      delete errors.state_license;
    }

    if (
      this.state.dea_license === null ||
      this.state.dea_license === undefined
    ) {
      errors.dea_license = "This field is required";
    } else {
      delete errors.dea_license;
    }
    if (this.state.email === "") {
      errors.email = "This field is required";
    } else {
      delete errors.email;
    }
    if (this.state.npi === "") {
      errors.npi = "This field is required";
    } else if (this.state.npi.length !== 10) {
      errors.npi = "Invalid NPI (10 Digits)";
    } else {
      delete errors.npi;
    }
    if (this.state.password === "") {
      errors.password = "This field is required";
    } else {
      delete errors.password;
    }
    if (this.state.personal_email === "") {
      errors.personal_email = "This field is required";
    } else {
      delete errors.personal_email;
    }
    if (this.state.practice_owner_first_name === "") {
      errors.practice_owner_first_name = "This field is required";
    } else {
      delete errors.practice_owner_first_name;
    }
    if (this.state.practice_owner_last_name === "") {
      errors.practice_owner_last_name = "This field is required";
    } else {
      delete errors.practice_owner_last_name;
    }
    if (this.state.practice_owner_phone === "") {
      errors.practice_owner_phone = "This field is required";
    } else {
      delete errors.practice_owner_phone;
    }
    if (this.state.phone1 === "") {
      errors.phone1 = "This field is required";
    } else {
      delete errors.phone1;
    }
    if (this.state.phone === "") {
      errors.phone = "This field is required";
    } else {
      delete errors.phone;
    }
    if (this.state.city === "") {
      errors.city = "This field is required";
    } else {
      delete errors.city;
    }
    if (this.state.street === "") {
      errors.street = "This field is required";
    } else {
      delete errors.street;
    }
    if (this.state.fax === "") {
      errors.fax = "This field is required";
    } else {
      delete errors.fax;
    }
    if (this.state.phone2 === "") {
      errors.phone2 = "This field is required";
    } else {
      delete errors.phone2;
    }
    if (this.state.state === "") {
      errors.state = "This field is required";
    } else {
      delete errors.state;
    }
    if (this.state.zip === "") {
      errors.zip = "This field is required";
    } else if (this.state.zip.length !== 5) {
      errors.zip = "Invalid Zip (5 Digits)";
    } else {
      delete errors.zip;
    }

    if (this.state.password2 === "") {
      errors.password2 = "This field is required";
    } else if (this.state.password !== this.state.password2) {
      errors.password2 = "Password not match";
    } else {
      delete errors.password2;
    }
    if (this.state.billing_city === "") {
      errors.billing_city = "This field is required";
    } else {
      delete errors.billing_city;
    }
    if (this.state.billing_street === "") {
      errors.billing_street = "This field is required";
    } else {
      delete errors.billing_street;
    }
    if (this.state.billing_state === "") {
      errors.billing_state = "This field is required";
    } else {
      delete errors.billing_state;
    }
    if (this.state.billing_zip === "") {
      errors.billing_zip = "This field is required";
    } else if (this.state.billing_zip.length !== 5) {
      errors.billing_zip = "Invalid Zip (5 Digits)";
    } else {
      delete errors.billing_zip;
    }
    if (this.state.shipping_city === "") {
      errors.shipping_city = "This field is required";
    } else {
      delete errors.shipping_city;
    }
    if (this.state.shipping_street === "") {
      errors.shipping_street = "This field is required";
    } else {
      delete errors.shipping_street;
    }
    if (this.state.shipping_state === "") {
      errors.shipping_state = "This field is required";
    } else {
      delete errors.shipping_state;
    }
    if (this.state.shipping_zip === "") {
      errors.shipping_zip = "This field is required";
    } else if (this.state.shipping_zip.length !== 5) {
      errors.shipping_zip = "Invalid Zip (5 Digits)";
    } else {
      delete errors.shipping_zip;
    }
    if (
      this.state.state_license_no === "" ||
      this.state.state_license_no === undefined
    ) {
      errors.state_license_no = "This field is required";
    } else {
      delete errors.state_license_no;
    }
    if (
      this.state.state_license_exp === "" ||
      this.state.state_license_exp === undefined
    ) {
      errors.state_license_exp = "This field is required";
    } else {
      delete errors.state_license_exp;
    }
    if (
      this.state.dea_license_exp === "" ||
      this.state.dea_license_exp === undefined
    ) {
      errors.dea_license_exp = "This field is required";
    } else {
      delete errors.dea_license_exp;
    }
    if (
      this.state.dea_license_no === "" ||
      this.state.dea_license_no === undefined
    ) {
      errors.dea_license_no = "This field is required";
    } else {
      delete errors.dea_license_no;
    }
    if (isEmpty(errors)) {
      this.register();
    } else {
      this.setState({
        errors,
      });
    }
  };
  checkbox = () =>
    this.setState({
      shipping_street: this.state.billing_street,
      shipping_city: this.state.billing_city,
      shipping_state: this.state.billing_state,
      shipping_zip: this.state.billing_zip,
      shipping_addressline_2: this.state.billing_addressline_2,
    });

  onChangeFile = (e) => {
    this.setState({
      [e.target.id]: e.target.files[0],
    });
  };

  register = () => {
    this.setState({ loading: true });
    const data = new FormData();
    data.append("state_license", this.state.state_license);
    data.append("dea_license", this.state.dea_license);
    data.append("name", this.state.name);
    data.append("email", this.state.email);
    data.append("password", this.state.password);
    data.append(
      "practice_owner_first_name",
      this.state.practice_owner_first_name
    );
    data.append(
      "practice_owner_last_name",
      this.state.practice_owner_last_name
    );
    data.append("practice_owner_phone", this.state.practice_owner_phone);
    data.append("street", this.state.street);
    data.append("addressline_2", this.state.addressline_2);
    data.append("city", this.state.city);
    data.append("state", this.state.state);
    data.append("zip", this.state.zip);
    data.append("shipping_street", this.state.shipping_street);
    data.append("shipping_addressline_2", this.state.shipping_addressline_2);
    data.append("shipping_city", this.state.shipping_city);
    data.append("shipping_state", this.state.shipping_state);
    data.append("shipping_zip", this.state.shipping_zip);

    data.append("billing_street", this.state.billing_street);
    data.append("billing_addressline_2", this.state.billing_addressline_2);
    data.append("billing_city", this.state.billing_city);
    data.append("billing_state", this.state.billing_state);
    data.append("billing_zip", this.state.billing_zip);
    data.append("phone", this.state.phone);
    data.append("phone1", this.state.phone1);
    data.append("phone2", this.state.phone2);
    data.append("fax", this.state.fax);
    data.append("npi", this.state.npi);
    data.append("rep", this.props.auth.currentStore.code);
    data.append("personal_email", this.state.personal_email);
    data.append("dea_license_no", this.state.dea_license_no);
    data.append("dea_license_exp", this.state.dea_license_exp);
    data.append("state_license_no", this.state.state_license_no);
    data.append("state_license_exp", this.state.state_license_exp);

    this.props.registerUser({ data, hideLoading: this.hideLoading });
  };
  hideLoading = () => {
    this.setState({ loading: false });
  };
  sendOtp = () => {
    this.setState({ loading: true, allowResend: false });
    const userData = {
      //   email: "mhariri@smartsrx.com",
      email: this.state.email,
      type: this.state.section,
    };
    axios({
      method: "POST",
      url: "/api/send-otp-register",
      data: userData,
    }).then((res) => {
      if (res.data.success) {
        this.setState({ section: "verifyOtp", loading: false });
        $("#otp").focus();
        setTimeout(() => {
          this.setState({ allowResend: true });
        }, 10000);
      } else {
        confirmAlert({
          title: "Error",
          message: res.data.message,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                this.setState({ otp: "" });
              },
            },
            {
              label: "Cancel",
              onClick: () => {},
            },
          ],
        });
        this.setState({ loading: false });
      }
    });
  };
  verifyOtp = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.otp === "") {
      errors.otp = "This field is required";
    } else {
      delete errors.otp;
    }
    if (isEmpty(errors)) {
      this.setState({ loading: true });
      const userData = {
        // email: "mhariri@smartsrx.com",
        email: this.state.email,
        otp: this.state.otp,
      };
      axios({
        method: "POST",
        url: "/api/verify-otp-register",
        data: userData,
      }).then((res) => {
        if (res.data.success) {
          this.setState({ loading: false });
          this.register();
        } else {
          this.setState({ loading: false });
          confirmAlert({
            title: "Error",
            message: res.data.message,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.setState({ otp: "" });
                },
              },
              {
                label: "Cancel",
                onClick: () => {},
              },
            ],
          });
        }
      });
    } else {
      this.setState({ errors, loading: false });
    }
  };

  render() {
    const { errors, allowResend } = this.state;
    return (
      <>
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div class="nam register" style={{ background: `#00c4ff24` }}>
          <div className="container" style={{ height: "100%" }}>
            <div className="row mt-6">
              <div className="col-md-9 mx-auto mt-5 mb-5 card shadow-lg">
                {this.state.section === "register" ? (
                  <div className="card-body p-1">
                    <h2
                      className="text-left text-primary mt-3 "
                      style={{ marginBottom: "20px" }}
                    >
                      <a href="https://www.psgarx.com/">
                        <img src={bona} height={50} />
                      </a>
                    </h2>

                    <form
                      noValidate
                      onSubmit={this.onSubmit}
                      encType="multipart/form-data"
                    >
                      {/* <div className="input-field col s12"> */}
                      <h2 className="text-left text-primary mt-3">
                        Business Detail
                      </h2>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="name">Practice NPI</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.npi}
                            id="npi"
                            type="text"
                            maxLength={10}
                            error={errors.npi}
                            className={classnames("form-control", {
                              invalid: errors.npi,
                            })}
                          />
                          <span className="text-danger">{errors.npi}</span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="email">
                            <b>Must Enter Practice Name Only</b>
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.name}
                            error={errors.name}
                            id="name"
                            type="name"
                            required
                            className={classnames("form-control", {
                              invalid: errors.name,
                            })}
                          />
                          <span className="text-danger">{errors.name}</span>
                        </div>
                      </div>

                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="street">Address Line 1</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.street}
                            id="street"
                            type="text"
                            error={errors.street}
                            className={classnames("form-control", {
                              invalid: errors.street,
                            })}
                          />
                          <span className="text-danger">{errors.street}</span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="addressline_2">Address Line 2</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.addressline_2}
                            id="addressline_2"
                            type="text"
                            error={errors.addressline_2}
                            className={classnames("form-control", {
                              invalid: errors.addressline_2,
                            })}
                          />
                          <span className="text-danger">
                            {errors.addressline_2}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="city">City</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.city}
                            id="city"
                            type="text"
                            error={errors.city}
                            className={classnames("form-control", {
                              invalid: errors.city,
                            })}
                          />
                          <span className="text-danger">{errors.city}</span>
                        </div>

                        <div className="col-md-6 col-12">
                          <label htmlFor="state">State</label>
                          <select
                            name="state"
                            onChange={this.onChange}
                            className="form-control"
                            id="state"
                          >
                            <option value="">Please Select</option>
                            {Object.keys(usState).map((each, i) => (
                              <option
                                key={i}
                                value={each}
                                selected={this.state.state === each}
                              >
                                {usState[each]}
                              </option>
                            ))}
                          </select>
                          <span className="text-danger">{errors.state}</span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmsuperadminlFor="zip">Zip</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.zip}
                            id="zip"
                            type="text"
                            error={errors.zip}
                            className={classnames("form-control", {
                              invalid: errors.zip,
                            })}
                          />
                          <span className="text-danger">{errors.zip}</span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="phone1">Office Phone</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.phone1}
                            id="phone1"
                            type="text"
                            error={errors.phone1}
                            className={classnames("form-control", {
                              invalid: errors.phone1,
                            })}
                          />
                          <span className="text-danger">{errors.phone1}</span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="fax">Fax</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.fax}
                            id="fax"
                            type="text"
                            error={errors.fax}
                            className={classnames("form-control", {
                              invalid: errors.fax,
                            })}
                          />
                          <span className="text-danger">{errors.fax}</span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="phone2">Alternate Phone</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.phone2}
                            id="phone2"
                            type="text"
                            error={errors.phone2}
                            className={classnames("form-control", {
                              invalid: errors.phone2,
                            })}
                          />
                          <span className="text-danger">{errors.phone2}</span>
                        </div>
                      </div>

                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="phone">
                            Mobile Phone - required for OTP
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.phone}
                            id="phone"
                            type="text"
                            error={errors.phone}
                            className={classnames("form-control", {
                              invalid: errors.phone,
                            })}
                          />
                          <span className="text-danger">{errors.phone}</span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="email">
                            Pharmacy email - required for login
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.email}
                            error={errors.email}
                            id="email"
                            type="text"
                            // required
                            className={classnames("form-control", {
                              invalid: errors.email,
                            })}
                          />
                          <span className="text-danger">{errors.email}</span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="password">Password</label>
                          <input
                            autoComplete={""}
                            onChange={this.onChange}
                            value={this.state.password}
                            error={errors.password}
                            id="password"
                            type="password"
                            className={classnames("form-control", {
                              invalid: errors.password,
                            })}
                          />
                          <span className="text-danger">{errors.password}</span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="password2">Confirm Password</label>
                          <input
                            autoComplete={""}
                            onChange={this.onChange}
                            value={this.state.password2}
                            id="password2"
                            type="password"
                            className={classnames("form-control", {
                              invalid: errors.password2,
                            })}
                          />
                          <span className="text-danger">
                            {errors.password2}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="state_license_no">
                            State License No.
                          </label>
                          <input
                            autoComplete={""}
                            onChange={this.onChange}
                            value={this.state.state_license_no}
                            error={errors.state_license_no}
                            id="state_license_no"
                            type="text"
                            className={classnames("form-control", {
                              invalid: errors.state_license_no,
                            })}
                          />
                          <span className="text-danger">
                            {errors.state_license_no}
                          </span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="state_license_exp">
                            State License Expiration
                          </label>
                          <input
                            autoComplete={""}
                            onChange={this.onChange}
                            value={this.state.state_license_exp}
                            id="state_license_exp"
                            type="text"
                            className={classnames("form-control", {
                              invalid: errors.state_license_exp,
                            })}
                          />
                          <span className="text-danger">
                            {errors.state_license_exp}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="dea_license_no">
                            Dea License No.
                          </label>
                          <input
                            autoComplete={""}
                            onChange={this.onChange}
                            value={this.state.dea_license_no}
                            error={errors.dea_license_no}
                            id="dea_license_no"
                            type="text"
                            className={classnames("form-control", {
                              invalid: errors.dea_license_no,
                            })}
                          />
                          <span className="text-danger">
                            {errors.dea_license_no}
                          </span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="dea_license_exp">
                            Dea License Expiration
                          </label>
                          <input
                            autoComplete={""}
                            onChange={this.onChange}
                            value={this.state.dea_license_exp}
                            id="dea_license_exp"
                            type="text"
                            className={classnames("form-control", {
                              invalid: errors.dea_license_exp,
                            })}
                          />
                          <span className="text-danger">
                            {errors.dea_license_exp}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="state_license">
                            Upload State License (PDF only)
                          </label>
                          <input
                            onChange={this.onChangeFile}
                            error={errors.state_license}
                            id="state_license"
                            type="file"
                            accept="application/pdf"
                            className={classnames("form-control", {
                              invalid: errors.state_license,
                            })}
                          />
                          <span className="text-danger">
                            {errors.state_license}
                          </span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="dea_license">
                            Upload DEA License (PDF only)
                          </label>
                          <input
                            onChange={this.onChangeFile}
                            error={errors.dea_license}
                            id="dea_license"
                            type="file"
                            accept="application/pdf"
                            className={classnames("form-control", {
                              invalid: errors.dea_license,
                            })}
                          />
                          <span className="text-danger">
                            {errors.dea_license}
                          </span>
                        </div>
                      </div>
                      <hr></hr>
                      <h2 className="text-left text-primary mt-3">
                        Billing Detail
                      </h2>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="billing_street">Address Line 1</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.billing_street}
                            id="billing_street"
                            type="text"
                            error={errors.billing_street}
                            className={classnames("form-control", {
                              invalid: errors.billing_street,
                            })}
                          />
                          <span className="text-danger">
                            {errors.billing_street}
                          </span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="billing_addressline_2">
                            Address Line 2
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.billing_addressline_2}
                            id="billing_addressline_2"
                            type="text"
                            error={errors.billing_addressline_2}
                            className={classnames("form-control", {
                              invalid: errors.billing_addressline_2,
                            })}
                          />
                          <span className="text-danger">
                            {errors.billing_addressline_2}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="billing_city">City</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.billing_city}
                            id="billing_city"
                            type="text"
                            error={errors.billing_city}
                            className={classnames("form-control", {
                              invalid: errors.billing_city,
                            })}
                          />
                          <span className="text-danger">
                            {errors.billing_city}
                          </span>
                        </div>

                        <div className="col-md-6 col-12">
                          <label htmlFor="billing_state">State</label>
                          <select
                            name="billing_state"
                            onChange={this.onChange}
                            className="form-control"
                            id="billing_state"
                          >
                            <option value="">Please Select</option>
                            {Object.keys(usState).map((each, i) => (
                              <option
                                key={i}
                                value={each}
                                selected={this.state.billing_state === each}
                              >
                                {usState[each]}
                              </option>
                            ))}
                          </select>
                          <span className="text-danger">
                            {errors.billing_state}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmsuperadminlFor="billing_zip">Zip</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.billing_zip}
                            id="billing_zip"
                            type="text"
                            error={errors.billing_zip}
                            className={classnames("form-control", {
                              invalid: errors.billing_zip,
                            })}
                          />
                          <span className="text-danger">
                            {errors.billing_zip}
                          </span>
                        </div>
                      </div>
                      <hr></hr>
                      <h2 className="text-left text-primary mt-3">
                        Shipping Detail
                      </h2>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <input
                            onChange={this.checkbox}
                            value={this.state.sameas}
                            id="sameas"
                            type="checkbox"
                            error={errors.sameas}
                          />
                          &nbsp;&nbsp;
                          <label htmlFor="sameas" className="form-check-label">
                            Same as Billing Address
                          </label>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="shipping_street">
                            Address Line 1
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.shipping_street}
                            id="shipping_street"
                            type="text"
                            error={errors.street}
                            className={classnames("form-control", {
                              invalid: errors.shipping_street,
                            })}
                          />
                          <span className="text-danger">
                            {errors.shipping_street}
                          </span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="shipping_addressline_2">
                            Address Line 2
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.shipping_addressline_2}
                            id="shipping_addressline_2"
                            type="text"
                            error={errors.shipping_addressline_2}
                            className={classnames("form-control", {
                              invalid: errors.shipping_addressline_2,
                            })}
                          />
                          <span className="text-danger">
                            {errors.shipping_addressline_2}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="shipping_city">City</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.shipping_city}
                            id="shipping_city"
                            type="text"
                            error={errors.shipping_city}
                            className={classnames("form-control", {
                              invalid: errors.shipping_city,
                            })}
                          />
                          <span className="text-danger">
                            {errors.shipping_city}
                          </span>
                        </div>

                        <div className="col-md-6 col-12">
                          <label htmlFor="shipping_state">State</label>
                          <select
                            name="shipping_state"
                            onChange={this.onChange}
                            className="form-control"
                            id="shipping_state"
                          >
                            <option value="">Please Select</option>
                            {Object.keys(usState).map((each, i) => (
                              <option
                                key={i}
                                value={each}
                                selected={this.state.shipping_state === each}
                              >
                                {usState[each]}
                              </option>
                            ))}
                          </select>
                          <span className="text-danger">
                            {errors.shipping_state}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmsuperadminlFor="shipping_zip">Zip</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.shipping_zip}
                            id="shipping_zip"
                            type="text"
                            error={errors.shipping_zip}
                            className={classnames("form-control", {
                              invalid: errors.shipping_zip,
                            })}
                          />
                          <span className="text-danger">
                            {errors.shipping_zip}
                          </span>
                        </div>
                      </div>
                      <hr></hr>
                      <h2 className="text-left text-primary mt-3">
                        Personal Detail
                      </h2>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="first_name">
                            Practice Owner First Name
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.practice_owner_first_name}
                            id="practice_owner_first_name"
                            type="text"
                            error={errors.practice_owner_first_name}
                            className={classnames("form-control", {
                              invalid: errors.practice_owner_first_name,
                            })}
                          />
                          <span className="text-danger">
                            {errors.practice_owner_first_name}
                          </span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="last_name">
                            Practice Owner Last Name
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.practice_owner_last_name}
                            id="practice_owner_last_name"
                            type="text"
                            error={errors.practice_owner_last_name}
                            className={classnames("form-control", {
                              invalid: errors.practice_owner_last_name,
                            })}
                          />
                          <span className="text-danger">
                            {errors.practice_owner_last_name}
                          </span>
                        </div>
                      </div>
                      <div className="row mt-12">
                        <div className="col-md-6 col-12">
                          <label htmlFor="practice_owner_phone">
                            Practice Owner Mobile
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.practice_owner_phone}
                            id="practice_owner_phone"
                            type="text"
                            error={errors.practice_owner_phone}
                            className={classnames("form-control", {
                              invalid: errors.practice_owner_phone,
                            })}
                          />
                          <span className="text-danger">
                            {errors.practice_owner_phone}
                          </span>
                        </div>
                        <div className="col-md-6 col-12">
                          <label htmlFor="email">Practice Owner Email</label>
                          <input
                            onChange={this.onChange}
                            value={this.state.personal_email}
                            id="personal_email"
                            type="email"
                            error={errors.personal_email}
                            className={classnames("form-control", {
                              invalid: errors.personal_email,
                            })}
                          />
                          <span className="text-danger">
                            {errors.personal_email}
                          </span>
                        </div>
                      </div>

                      <p className="text-center pb-0 mt-2">
                        <button
                          type="submit"
                          // data-toggle="modal" data-target="#term-condition"
                          className="btn btn-large btn-primary mt-2 px-5"
                        >
                          Register
                        </button>
                      </p>
                      <h5 className="text-center pb-0 mt-2">
                        Already have an account? <Link to="/login">Log in</Link>
                      </h5>

                      {/* </div> */}
                    </form>
                  </div>
                ) : (
                  <div className="card-body p-1">
                    <h2
                      className="text-center text-primary mt-2 "
                      style={{ marginBottom: "10px" }}
                    >
                      <a href="https://www.psgarx.com/">
                        <img src={bona} height={50} />
                      </a>
                    </h2>
                    <h3>OTP Verification</h3>
                    <h5>OTP send on your Pharmacy email address</h5>
                    <h6></h6>
                    <form
                      noValidate
                      onSubmit={this.verifyOtp}
                      className="white"
                    >
                      <label htmlFor="email">Enter OTP </label>
                      <input
                        onChange={this.onChange}
                        value={this.state.otp}
                        error={errors.otp}
                        id="otp"
                        type="otp"
                        className={classnames("form-control", {
                          invalid: errors.otp,
                        })}
                      />
                      <span className="text-danger">{errors.otp}</span>
                      {allowResend && (
                        <a
                          href="#"
                          className="float-right"
                          onClick={this.sendOtp}
                        >
                          Resend OTP
                        </a>
                      )}
                      <br></br>
                      <p className="text-center pb-0 mt-2 mb-0">
                        <button
                          type="submit"
                          className="btn btn-large btn-primary mt-2 px-5"
                        >
                          Verify OTP
                        </button>
                      </p>
                    </form>
                  </div>
                )}

                {/* <TermRegister register={this.register} /> */}
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
