import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import React, { Component } from "react";
import Login from "./components/auth/Login";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import Navbar from "./components/partials/Navbar";
import Sidebar from "./components/partials/Sidebar";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/font-awesome/css/font-awesome.css";
import "../node_modules/jquery/dist/jquery.min";
import "../node_modules/popper.js/dist/popper";

import User from "./components/pages/Users";
import OrderList from "./components/pages/OrderList";
import RouteData from "./components/RouteData";

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "./login";
  }
}

if (window.location.host === "glp1rxdirect.com") {
  window.location.href = "https://buy.glp1rxdirect.com/";
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <RouteData />
      </Provider>
    );
  }
}

export default App;
