import React, { Component } from "react";
import ReactDatatable from "../../utils/ReactDatatable";
import { connect } from "react-redux";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import members from "../../utils/members.json";
import Navbar from "../partials/Navbar";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import UserUpdateModal from "../partials/UserUpdateModal";
class Users extends Component {
  constructor(props) {
    super(props);
    const memberD = Object.values(members).find(
      (eachD) => eachD.email === this.props.auth.user.email
    );
    this.columns = [
      {
        key: "npi",
        text: "Practice NPI",
        className: "npi",
        align: "left",
        sortable: true,
      },
      {
        key: "name",
        text: "Practice Name",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "store",
        text: "REP",
        className: "store",
        align: "left",
        sortable: true,
      },
      {
        key: "phone",
        text: "Mobile Phone",
        className: "phone",
        align: "left",
        sortable: true,
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "date_formatted",
        text: "Date",
        className: "date_formatted",
        align: "left",
        sortable: true,
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <>
              {memberD === undefined && (
                <span>
                  <Link to={`order-list?email=${record.email}`}>
                    View Orders
                  </Link>
                  &nbsp; /&nbsp;
                </span>
              )}
              <span>
                <Link to={`user-view/${record._id}`}>View Customer</Link>
              </span>
              {this.props.auth.user.email === "mehrdadhairi@gmail.com" && (
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#update-subuser-modal"
                  onClick={() => this.editRecord(record)}
                  style={{ marginRight: "5px" }}
                >
                  &nbsp; /&nbsp; Edit User
                </a>
              )}
            </>
          );
        },
      },
    ];
    this.config = {
      page_size: 20,
      length_menu: [20, 50, 100, 500],
      filename: "User List",
      no_data_text: "No Log found!",
      button: {
        excel: false,
        print: false,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: false,
      show_pagination: true,
      show_info: true,
      sort: {
        column: "date",
        order: "desc",
      },
    };

    this.state = {
      records: [],
      totalCount: 0,
      filterData: {
        page_number: 1,
        page_size: 2000,
        sort_order: { column: "date", order: "desc" },
      },
      currentRecord: {},
      date: undefined,
      recordsGraph: [],
      rep: undefined,
      userOrders: [],
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
    if (this.props.auth.user.superadmin) {
      axios.post("/api/user-order").then((res) => {
        this.setState({
          userOrders: res.data.records,
        });
      });
    }
  }

  getData() {
    this.setState({ loading: true });
    let filterData = this.state.filterData;
    filterData.search = {
      ...(this.state.rep !== "" && { rep: this.state.rep }),
      ...(this.state.from_date !== "" && { from_date: this.state.from_date }),
      ...(this.state.to_date !== "" && { from_date: this.state.to_date }),
      email: this.props.auth.user.email,
      superadmin: this.props.auth.user.superadmin,
    };
    axios
      .post("/api/users", filterData)
      .then((res) => {
        var result = [];

        // add data rows in sheet array
        for (let i in res.data.records) {
          let each = res.data.records[i];
          each.date_formatted = each.date
            ? moment(each.date).add(5, "hours").format("L")
            : "";
          const memberD = Object.values(members).find(
            (eachD) => eachD.code === each.rep
          );
          each.store = memberD ? memberD.name : "";
          result.push(each);
        }
        this.setState({
          records: result,
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  }

  editRecord(record) {
    this.setState({ currentRecord: record });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSearch = (e) => {
    e.preventDefault();
    this.getData();
  };

  filter = (filterData) => {
    this.setState({ filterData }, () => this.getData());
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  resetSearch = () => {
    this.setState(
      {
        rep: undefined,
        filterData: {
          sort_order: { column: "_id", order: "desc" },
        },
      },
      () => this.getData()
    );
  };

  exportToCSV = () => {
    toast.success("Dowloading...Please Wait!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    var headers = {
      npi: "Practice NPI",
      name: "Practice Name",
      store: "REP",
      phone: "Mobile Phone",
      email: "Email",
      date_formatted: "Date",
    };
    var records = [];
    // add data rows in sheet array
    this.setState({ loading: false });
    let filterData = {
      page_number: 1,
      page_size: 20000,
      sort_order: { column: "date", order: "desc" },
    };
    filterData.search = {
      ...(this.state.rep !== "" && { rep: this.state.rep }),
      ...(this.state.from_date !== "" && { from_date: this.state.from_date }),
      ...(this.state.to_date !== "" && { from_date: this.state.to_date }),
      email: this.props.auth.user.email,
      superadmin: this.props.auth.user.superadmin,
    };
    axios
      .post("/api/users", filterData)
      .then((res) => {
        // add data rows in sheet array
        for (let i in res.data.records) {
          let each = res.data.records[i];
          delete each._id;
          delete each.id;
          delete each.__v;
          delete each.user;
          each.date_formatted = each.date
            ? moment(each.date).add(5, "hours").format("L")
            : "";
          const memberD = Object.values(members).find(
            (eachD) => eachD.code === each.rep
          );
          each.store = memberD ? memberD.name : "";
          records.push(each);
        }
        if (headers) {
          records.unshift(headers);
        }
        let jsonObject = JSON.stringify(records);
        let csv = this.convertToCSV(jsonObject, headers);
        let exportedFilenmae = this.config.filename + ".csv" || "export.csv";
        var blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
          type: "application/csv;charset=UTF-8",
        });
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          let link = document.createElement("a");
          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  convertToCSV(objArray, headers) {
    const keys = Object.keys(headers);

    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in keys) {
        line +=
          array[i][keys[index]] === undefined
            ? ","
            : `"${array[i][keys[index]]}",`;
        line.trim(",");
      }
      str += line + "\r\n";
    }
    return str;
  }

  render() {
    const foundMember = Object.values(members).find(
      (each) => each.email === this.props.auth.user.email
    );
    let records = this.state.records;
    records.filter((each) => {
      if (each.rep === "buy") {
        if (
          this.state.userOrders.find(
            (eachOrder) => eachOrder.email === each.email
          )
        ) {
          return true;
        } else if (moment(each.date) <= moment("2024-03-06")) {
          return true;
        }
      } else {
        return true;
      }
    });
    return (
      <>
        <div style={{ width: "100%" }}>
          <Navbar />
          {this.state.loading && (
            <div className="loading-wrapper">
              <PulseLoader color="#007bff" loading={true} size={20} />
            </div>
          )}
          <UserUpdateModal
            record={this.state.currentRecord}
            getData={this.getData}
          />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <h1 className="mt-2 text-primary">User List</h1>
              {foundMember === undefined && (
                <>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <label htmlFor="npi">REP</label>
                      <select
                        name="rep"
                        onChange={this.onChange}
                        className="form-control"
                        id="rep"
                      >
                        <option value="" selected="">
                          All
                        </option>
                        {Object.values(members).map((each, i) => (
                          <option
                            key={i}
                            value={each.code}
                            selected={this.state.rep === each.code}
                          >
                            {each.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <button
                        onClick={this.onSearch}
                        type="button"
                        className="btn btn-primary"
                      >
                        Search
                      </button>
                      &nbsp;
                      <button
                        type="reset"
                        onClick={this.resetSearch}
                        className="btn btn-outline-secondary"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </>
              )}
              <br></br>

              <ReactDatatable
                config={this.config}
                records={records}
                columns={this.columns}
                dynamic={false}
                loading={true}
                exportToCSV={this.exportToCSV}
              />
            </div>
          </div>
          <ToastContainer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  multistoreData: state.multistoreData,
});

export default connect(mapStateToProps, {})(Users);
