import React from "react";

function CustomAlert(props) {
  return (
    <div className="custom-ui">
      <h1>Pin</h1>
      <input
        onChange={props.onChange}
        value={props.pin}
        id="pin"
        type="text"
        className="form-control"
      />
      <button
        onClick={() => {
          props.handleCheckPin(props.record);
          props.onClose();
        }}
      >
        Verify
      </button>
    </div>
  );
}

export default CustomAlert;
