import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faList,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { Link } from "react-router-dom";
import psga from "../../utils/psga_new.png";

class Sidebar extends Component {
  render() {
    return (
      <div className="border-right h-100" id="sidebar-wrapper">
        <div className="list-group list-group-flush">
          <Link
            to="/dashboard"
            className="list-group-item list-group-item-action"
          >
            <img src={psga} height={32} />
          </Link>
          {!this.props.auth.user.superadmin &&
            !this.props.auth.user.provider &&
            this.props.auth.user.email !== "mehrdadhairi@gmail.com" && (
              <>
                <Link
                  to="/dashboard"
                  className="list-group-item list-group-item-action"
                >
                  <FontAwesomeIcon icon={faCartPlus} /> Request
                </Link>
                {/* <Link
                to="/api"
                className="list-group-item list-group-item-action"
              >
                <FontAwesomeIcon icon={faCartPlus} /> API
              </Link> */}
              </>
            )}
          <Link
            to="/order-list"
            className="list-group-item list-group-item-action"
          >
            <FontAwesomeIcon icon={faList} /> Request History
          </Link>
          {(this.props.auth.user.superadmin || this.props.auth.user.provider) &&
            this.props.auth.user.email !== "order@pqpharmacy.com" &&
            this.props.auth.user.email !== "mehrdadhairi@gmail.com" && (
              <Link
                to="/users"
                className="list-group-item list-group-item-action"
              >
                <FontAwesomeIcon icon={faUserAlt} /> Users
              </Link>
            )}
        </div>
        {this.props.auth.user.email === "mehrdadhairi@gmail.com" && (
          <>
            <Link
              to="/users"
              className="list-group-item list-group-item-action"
            >
              <FontAwesomeIcon icon={faUserAlt} /> Users
            </Link>
          </>
        )}
      </div>
    );
  }
}

Sidebar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Sidebar);
