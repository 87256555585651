import React from "react";
import classnames from "classnames";
import { logoutUser } from "../../actions/authActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import isEmpty from "is-empty";
import { ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import Navbar from "../partials/Navbar";
import { confirmAlert } from "react-confirm-alert";
import "react-toastify/dist/ReactToastify.css";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      password: undefined,
      password2: undefined,
      changePassword: undefined,
      errors: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ loading: true });
    let filterData = {};
    filterData.search = {
      _id: this.props.auth.user.id,
    };
    filterData.sort_order = {
      order: "asc",
      column: "_id",
    };
    filterData.find = "all";
    axios
      .post("/api/users", filterData)
      .then((res) => {
        let result = res.data.records[0];
        this.setState({
          id: result.id,
          loading: false,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
            window.location.href = "./login";
          }
        }
      });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onUserUpdate = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.changePassword) {
      if (this.state.password === "") {
        errors.password = "This field is required";
      } else {
        delete errors.password;
      }
      if (this.state.password2 === "") {
        errors.password2 = "This field is required";
      } else if (this.state.password !== this.state.password2) {
        errors.password2 = "Password not match";
      } else {
        delete errors.password2;
      }
    }

    if (isEmpty(errors)) {
      if (
        this.state.email !== this.state.emailOld &&
        this.props.auth.user.user_type === undefined
      ) {
        confirmAlert({
          title: "Confirm",
          message:
            "Are you sure you want to change email. Note: Your new email will be your new username",
          buttons: [
            {
              label: "Yes",
              onClick: () => this.finalUpdate(true),
            },
            {
              label: "No",
              onClick: () => {},
            },
          ],
        });
      } else {
        this.finalUpdate(false);
      }
    } else {
      this.setState({ errors });
    }
  };

  finalUpdate = (emailUpdate) => {
    const newUser = {
      id: this.state.id,
      password: this.state.password,
    };

    if (this.state.changePassword) {
      newUser.password = this.state.password;
    }
    axios
      .post("/api/user-update", newUser)
      .then((res) => {
        this.getData();
        confirmAlert({
          title: "Success",
          message: "Profile updated successfully",
          buttons: [
            {
              label: "Ok",
              onClick: () => window.location.reload(),
            },
          ],
        });
        this.setState({
          loading: false,
          password: undefined,
          password2: undefined,
        });
      })
      .catch((err) => console.log(err));
  };

  toggleChange = (e) => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  render() {
    const { errors } = this.state;
    return (
      <div style={{ width: "100%" }}>
        <Navbar />
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div id="page-content-wrapper">
          <div className="container-fluid profile">
            <form noValidate onSubmit={this.onUserUpdate} id="update-user">
              <h2 className="text-left text-primary mt-3">Profile Update</h2>
              <div className="row mt-2">
                <div className="col-md-12 change-pass">
                  <label htmlFor="changePassword">Change Password</label>
                  &nbsp;&nbsp;
                  <input
                    type="checkbox"
                    defaultChecked={this.state.changePassword}
                    onClick={this.toggleChange}
                    id="changePassword"
                    className="dispense-checkbox"
                  />
                </div>
              </div>
              {this.state.changePassword ? (
                <div className="row mt-12">
                  <div className="col-md-6 col-12">
                    <label htmlFor="password">Password</label>
                    <input
                      autoComplete={""}
                      onChange={this.onChange}
                      value={this.state.password}
                      error={errors.password}
                      id="password"
                      type="password"
                      className={classnames("form-control", {
                        invalid: errors.password,
                      })}
                    />
                    <span className="text-danger">{errors.password}</span>
                  </div>

                  <div className="col-md-6 col-12">
                    <label htmlFor="password2">Confirm Password</label>
                    <input
                      autoComplete={""}
                      onChange={this.onChange}
                      value={this.state.password2}
                      id="password2"
                      type="password"
                      className={classnames("form-control", {
                        invalid: errors.password2,
                      })}
                    />
                    <span className="text-danger">{errors.password2}</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <br></br>
              <button
                form="update-user"
                type="submit"
                className="btn btn-primary"
              >
                Update User
              </button>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { logoutUser })(withRouter(Profile));
