import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faSignOutAlt,
  faUserAlt,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { Link, NavLink } from "react-router-dom";

class Navbar extends Component {
  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      window.location.href = "/login";
    }
  }

  render() {
    const { user } = this.props.auth;
    return (
      <div className="container-fluid p-0">
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link to="/order-list" className="nav-link">
                  <FontAwesomeIcon icon={faList} /> Request History
                </Link>
              </li>
              <li className="nav-item dropdown active">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="lookups"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faWrench} />
                  {"  "}My Account
                </a>
                <ul className="dropdown-menu" aria-labelledby="lookups">
                  <li>
                    <NavLink
                      activeClassName="is-active"
                      to={"/profile"}
                      className="list-group-item"
                    >
                      Settings
                    </NavLink>
                  </li>
                </ul>
              </li>
              {(this.props.auth.user.superadmin ||
                this.props.auth.user.provider) &&
                this.props.auth.user.email !== "order@pqpharmacy.com" && (
                  <li className="nav-item active">
                    <Link to="/users" className="nav-link">
                      <FontAwesomeIcon icon={faUserAlt} /> Users
                    </Link>
                  </li>
                )}

              <li className="nav-item active">
                <a className="nav-link" href="#" onClick={this.onLogoutClick}>
                  Logout ({user.name}) <FontAwesomeIcon icon={faSignOutAlt} />{" "}
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
