import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "../components/pages/Dashboard";
import Api from "../components/pages/Api";
import React, { Component } from "react";
import Login from "../components/auth/Login";
import PrivateRoute from "../components/private-route/PrivateRoute";
import Register from "../components/auth/Register";
import Sidebar from "../components/partials/Sidebar";
import Profile from "./pages/Profile";
import User from "../components/pages/Users";
import OrderList from "../components/pages/OrderList";
import { setCurrentStore } from "../actions/userActions";
import { connect } from "react-redux";
import members from "../utils/members.json";
import Forgot from "./auth/Forgot";
import ViewUsers from "./pages/ViewUsers";
import ResetPassword from "./auth/ResetPassword";
class RouteData extends Component {
  componentDidMount() {
    let curr = members[window.location.hostname.split(".")[0]];
    if (curr !== undefined && !this.props.auth.currentStore.code) {
      this.props.setCurrentStore(curr);
    } else {
      console.log("No Store");
    }
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot" component={Forgot} />
            <Route
              path="/password-reset/:userId/:token"
              component={ResetPassword}
            />
            <div>
              <div className="d-flex" id="wrapper">
                <Sidebar />
                <Switch>
                  <PrivateRoute exact path="/profile" component={Profile} />
                  {!this.props.auth.user.provider &&
                    !this.props.auth.user.superadmin &&
                    this.props.auth.user.email !== "mehrdadhairi@gmail.com" && (
                      <PrivateRoute
                        exact
                        path="/dashboard"
                        component={Dashboard}
                      />
                    )}
                  {!this.props.auth.user.provider &&
                    !this.props.auth.user.superadmin && (
                      <PrivateRoute exact path="/api" component={Api} />
                    )}
                  <PrivateRoute
                    exact
                    path="/order-list"
                    component={OrderList}
                  />
                  <PrivateRoute
                    exact
                    path="/user-view/:_id"
                    component={ViewUsers}
                  />
                  {(this.props.auth.user.superadmin ||
                    this.props.auth.user.provider) && (
                    <>
                      {this.props.auth.user.email !==
                        "order@pqpharmacy.com" && (
                        <PrivateRoute exact path="/users" component={User} />
                      )}
                      <PrivateRoute
                        exact
                        path="/dashboard"
                        component={OrderList}
                      />
                    </>
                  )}
                  {this.props.auth.user.email === "mehrdadhairi@gmail.com" && (
                    <>
                      <PrivateRoute
                        exact
                        path="/dashboard"
                        component={OrderList}
                      />
                      <PrivateRoute exact path="/users" component={User} />
                    </>
                  )}
                </Switch>
              </div>
            </div>
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { setCurrentStore })(RouteData);
