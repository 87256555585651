import React, { Component } from "react";
import moment from "moment";

class Footer extends Component {
  render() {
    return (
      <div className="my-1">
        <footer className="text-center text-lg-start text-white bg-primary">
          <div className="container p-4 pb-0">
            <section className="p-2 pt-0">
              <div className="row d-flex align-items-center">
                <div className="col-md-12 col-lg-12 text-center text-md-start">
                  <div className="p-1">Copyright {moment().year()}</div>
                  <div className="">GLP1RXDIRECT</div>
                </div>
              </div>
            </section>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
