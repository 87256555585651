import React from "react";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export const registerUser = ({ data, hideLoading }) => (dispatch) => {
  axios
    .post("/api/register", data, {})
    .then((res) => {
      hideLoading();
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <h1 className="mt-2 text-primary">Message</h1>
              <p className="help-text font-weight-bold h6">
                your account is created, please click to login
              </p>
              <button
                className="btn btn-primary btn-sm registerbtn"
                onClick={() => {
                  onClose();
                  window.location.href = "/login";
                }}
              >
                Login Now
              </button>
            </div>
          );
        },
      });
    })

    .catch((err) => {
      hideLoading();
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const loginUser = (token) => (dispatch) => {
  localStorage.setItem("jwtToken", token);
  setAuthToken(token);
  const decoded = jwt_decode(token);
  dispatch(setCurrentUser(decoded));
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  window.location.href = "/login";
};
