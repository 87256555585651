import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import axios from "axios";
import isEmpty from "is-empty";

import "react-toastify/dist/ReactToastify.css";

class UserUpdateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.record.id,
      name: "",
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.record) {
      this.setState({
        ...nextProps.record,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.message !== undefined &&
      nextProps.auth.user.data.success
    ) {
      $("#update-subuser-modal").modal("hide");
      toast(nextProps.auth.user.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
  setFromDate = (date) => {
    this.setState({ assigned_date: date });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onUserUpdate = (e) => {
    e.preventDefault();
    let { errors } = this.state;

    if (this.state.name === "") {
      errors.name = "This field is required";
    } else {
      delete errors.name;
    }

    if (isEmpty(errors)) {
      const newUser = {
        id: this.state.id,
        name: this.state.name,
      };

      axios
        .post("/api/user-update", newUser)
        .then((res) => {
          this.props.getData();
          $("#update-subuser-modal").modal("hide");
        })
        .catch((err) => console.log(err));
    } else {
      this.setState({ errors });
    }
  };

  onChangeSelect = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-subuser-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update User</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onUserUpdate}
                  id="update-subuser"
                >
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Name</label>
                    </div>
                    <div className="col-md-9">
                      <input
                        onChange={this.onChange}
                        value={this.state.name}
                        id="name"
                        type="text"
                        error={errors.name}
                        className={classnames("form-control", {
                          invalid: errors.name,
                        })}
                      />
                      <span className="text-danger">{errors.name}</span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="update-subuser"
                  data-toggle="modal"
                  data-target="#update-user-modal"
                  type="submit"
                  className="btn btn-primary"
                >
                  Update User
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(withRouter(UserUpdateModal));
