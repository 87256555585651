import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ViewUsers extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      record: undefined,
      errors: {},
    };
  }
  componentDidMount() {
    const filterData = {
      page_number: 1,
      page_size: 20,
      sort_order: { column: "_id", order: "asc" },
      search: { _id: this.props.match.params._id },
    };
    axios
      .post(`/api/users`, filterData)
      .then((res) => {
        this.setState({
          record: res.data.records[0],
          totalCount: res.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.response) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  }

  downloadDoc = (url) => {
    axios
      .post(
        "/api/send-image",
        { url },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(pdfBlob);
        const tempLink = document.createElement("a");
        tempLink.href = url;
        tempLink.setAttribute("download", `${url}.pdf`); // Set the desired filename for the downloaded file
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {});
  };

  goBack() {
    this.props.history.goBack();
  }
  render() {
    // console.log(this.state)
    const { errors } = this.state;
    return (
      <div className="d-flex" id="wrapper">
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        {this.state.record !== undefined && (
          <table className="table">
            <thead className="">
              <th scope="col" colSpan={2}>
                Personal Detail
              </th>
            </thead>
            <tr>
              <td>Practice Owner Name</td>
              <td>
                {this.state.record.practice_owner_first_name +
                  " " +
                  this.state.record.practice_owner_last_name}
              </td>
            </tr>
            <tr>
              <td>Practice Owner Mobile</td>
              <td>{this.state.record.practice_owner_phone}</td>
            </tr>
            <tr>
              <td>Practice Owner Email</td>
              <td>{this.state.record.personal_email}</td>
            </tr>
            <th scope="col" colSpan={2}>
              Business Detail
            </th>

            <tr>
              <td>Practice NPI</td>
              <td>{this.state.record.npi}</td>
            </tr>
            <tr>
              <td>Practice Name</td>
              <td>{this.state.record.name}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>
                {this.state.record.street +
                  " " +
                  this.state.record.addressline_2 +
                  "," +
                  this.state.record.city +
                  "," +
                  this.state.record.state +
                  "," +
                  this.state.record.zip}
              </td>
            </tr>
            <tr>
              <td>Office Phone</td>
              <td>{this.state.record.phone1}</td>
            </tr>
            <tr>
              <td>Fax</td>
              <td>{this.state.record.fax}</td>
            </tr>
            <tr>
              <td>Alternate Phone</td>
              <td>{this.state.record.phone1}</td>
            </tr>
            <tr>
              <td>Pharmacy email</td>
              <td>{this.state.record.email}</td>
            </tr>
            <tr>
              <td>Mobile Phone</td>
              <td>{this.state.record.phone}</td>
            </tr>
            <th scope="col" colSpan={2}>
              Billing Detail
            </th>

            <tr>
              <td>Address</td>
              <td>
                {this.state.record.billing_street +
                  " " +
                  this.state.record.billing_addressline_2 +
                  "," +
                  this.state.record.billing_city +
                  "," +
                  this.state.record.billing_state +
                  "," +
                  this.state.record.billing_zip}
              </td>
            </tr>
            <th scope="col" colSpan={2}>
              Shipping Detail
            </th>

            <tr>
              <td>Address</td>
              <td>
                {this.state.record.shipping_street +
                  "," +
                  this.state.record.shipping_addressline_2 +
                  "," +
                  this.state.record.shipping_city +
                  "," +
                  this.state.record.shipping_state +
                  "," +
                  this.state.record.shipping_zip}
              </td>
            </tr>

            <tr>
              <td>State License</td>
              <td>
                <span
                  onClick={() =>
                    this.downloadDoc(this.state.record.state_license)
                  }
                  style={{ cursor: "pointer", color: "#2A3E90" }}
                >
                  Download
                </span>
              </td>
            </tr>
            <tr>
              <td>Dea License</td>
              <td>
                <span
                  onClick={() =>
                    this.downloadDoc(this.state.record.dea_license)
                  }
                  style={{ cursor: "pointer", color: "#2A3E90" }}
                >
                  Download
                </span>
              </td>
            </tr>

            <button
              type="button"
              class="btn btn-dark btn-lg"
              onClick={this.goBack}
            >
              Go Back
            </button>
          </table>
        )}
        <ToastContainer />
      </div>
    );
  }
}
export default withRouter(ViewUsers);
